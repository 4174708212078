<template>
  <div>
    <h1 class="heading">Ein Fehler ist aufgetreten.</h1>
    <button class="try-again-button" @click="tryAgain">Erneut probieren</button>
    <p
      >Klicken Sie auf "Erneut probieren", um die vorherige Seite noch einmal zu laden. Sollte der Fehler erneut
      auftreten, wenden Sie sich an das Entwicklerheld-Team.</p
    >
    <a href="mailto:kontakt@entwicklerheld.de">Kontakt per E-Mail</a>
  </div>
</template>

<script>
import { services } from "@/main";
import router from "@/router";

export default {
  data() {
    return {};
  },
  methods: {
    tryAgain: function() {
      let next = this.$route.query.next;
      router.push({ path: next });
    },
  },
};
</script>

<style lang="scss" scoped>
.heading {
  margin-bottom: 2rem;
}

.try-again-button {
  margin-bottom: 10px;
}
</style>
